import React, { useCallback, useMemo, useRef, useState } from 'react';
import { IconButton, InputAdornment, Modal, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRouter } from 'next/navigation';

import { SearchIcon } from 'components/icons';
import { colors, styled } from 'core/styles';
import { isEvmAddressHex } from 'utils/isEvmAddressHex';
import { useRouteUtils } from 'routes';

import { AccountAddressResult } from './AccountAddressResult';
import { TopAddresses } from './TopAddresses';

export function AccountSearch() {
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { redirectTo } = useRouteUtils();
  const router = useRouter();

  const { displayResult, isAddress } = useMemo(() => {
    return {
      displayResult: searchInputValue.length >= 5,
      isAddress: isEvmAddressHex(searchInputValue),
    };
  }, [searchInputValue]);

  const openSearch = useCallback(() => {
    setSearchIsOpen(true);
  }, []);

  const closeSearch = useCallback(() => {
    setSearchInputValue('');
    setSearchIsOpen(false);
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchInputValue(event.target.value);
    },
    [],
  );

  const setFocusToInputSearch = () => {
    const input = inputRef.current?.querySelector('input');
    input?.focus();
  };

  const clearInput = useCallback(() => {
    setSearchInputValue('');
    setFocusToInputSearch();
  }, []);

  const handleTopAddressClick = useCallback((address: string) => {
    setSearchInputValue(address);
    setFocusToInputSearch();
  }, []);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (isAddress) {
        const redirectPath = redirectTo.globalAccount({ accountId: searchInputValue }).href;
        router.push(redirectPath);
        closeSearch();
      }
    },
    [closeSearch, isAddress, redirectTo, router, searchInputValue],
  );

  return (
    <>
      <Root isActive={searchIsOpen} onClick={openSearch}>
        <IconContainer>
          <SearchIcon fontSize="inherit" />
        </IconContainer>
        Search
      </Root>
      <Modal open={searchIsOpen} onClose={closeSearch}>
        <SearchBox onSubmit={handleSubmit}>
          <TextFieldInput
            placeholder="Search by wallet address"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconContainer>
                    <SearchIcon fontSize="inherit" />
                  </IconContainer>
                </InputAdornment>
              ),
              endAdornment:
                searchInputValue.length > 0 ? (
                  <InputAdornment position="end">
                    <IconButton onClick={clearInput} color="inherit">
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              ref: inputRef,
            }}
            fullWidth
            onChange={handleInputChange}
            autoFocus={searchIsOpen}
            value={searchInputValue}
          />
          {displayResult ? (
            <SearchResults>
              {isAddress ? (
                <AccountAddressResult address={searchInputValue} onLinkClick={closeSearch} />
              ) : (
                'There are no matches for your request'
              )}
            </SearchResults>
          ) : (
            <TopAddresses onButtonClick={handleTopAddressClick} />
          )}
        </SearchBox>
      </Modal>
    </>
  );
}

const Root = styled('div', {
  shouldForwardProp: prop => !['isActive'].includes(String(prop)),
})<{
  isActive: boolean;
}>(({ isActive }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  cursor: isActive ? 'default' : 'pointer',
  transition: 'all 200ms',
  padding: '4px 12px 4px 6px',
  borderRadius: '100px',
  whiteSpace: 'nowrap',
  background: isActive ? colors.silver : colors.smokyWhite,
  color: isActive ? colors.black : colors.pearlDarkGray,

  '&:focus': {
    outline: 'none',
  },

  '&:hover': {
    background: isActive ? colors.white : colors.silver,
  },
  '&:active': {
    background: isActive ? colors.silver : colors.smokyWhite,
    color: colors.white,
  },
}));

const IconContainer = styled('span')({
  fontSize: 24,
  marginRight: 4,
  color: 'inherit',
});

const SearchBox = styled('form')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: 0,
  transform: 'translate(0, -50%)',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  width: '100%',
  maxHeight: '100%',
  overflow: 'scroll',
  padding: '60px 20px 20px',
  outline: 'none',
  fontSize: 14,

  [theme.breakpoints.up('mobileMD')]: {
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 477,
  },
}));

const TextFieldInput = styled(TextField)({
  borderRadius: 8,
  border: 'none',
  color: colors.white,

  '& .MuiInputBase-root': {
    background: colors.middleGray,
    paddingLeft: 6,
    paddingRight: 4,
    fontSize: 14,

    '&.Mui-focused': {
      background: colors.smokyWhite,
    },
  },

  '& input': {
    padding: '12.5px 12px 12.5px 0',
  },

  '& fieldset': {
    border: 'none',
  },

  '& .MuiInputAdornment-root': {
    color: colors.black,
    margin: 0,

    '& .MuiButtonBase-root': {
      padding: 5,
    },
  },
});

const SearchResults = styled('div')({
  background: colors.smokyWhite,
  borderRadius: 8,
  padding: 12,

  '&:hover': {
    background: colors.middleGray,
  },
});
