import React from 'react';
import { Button } from '@mui/material';

import { colors, styled } from 'core/styles';
import { getShortAddress } from 'utils/format';
import { AddressIcon } from 'components';

const topAddresses = [
  '0x31d3243cfb54b34fc9c73e1cb1137124bd6b13e1',
  '0x705f8f0eaac561aabb1d5d8f4d0dab36d5a62e86',
  '0x8cf877e91118ab50ff00de1e6e6a566c9c2b8228',
  '0x50e3449024632221562c6bd11e1d5d4a6cc06a3f',
  '0x7a16ff8270133f063aab6c9977183d9e72835428',
];

export function TopAddresses({ onButtonClick }: { onButtonClick: (address: string) => void }) {
  return (
    <Root>
      {topAddresses.map(address => (
        <AddressButton key={address} onClick={() => onButtonClick(address)}>
          <IconBox>
            <AddressIcon address={address} fontSize="inherit" />
          </IconBox>
          {getShortAddress(address, 6, 3)}
        </AddressButton>
      ))}
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
});

const AddressButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  padding: '4px 12px 4px 8px',
  fontSize: 14,
  background: colors.smokyWhite,
  borderRadius: 16,
  textTransform: 'lowercase',

  '&:hover': {
    background: colors.middleGray,
  },
});

const IconBox = styled('div')({
  fontSize: 20,
});
