import React from 'react';
import { styled } from '@mui/material/styles';

import { useRouteUtils } from 'routes';
import { colors } from 'core/styles';
import {
  Logo as LogoComponent,
  LogoFull as LogoFullComponent,
  DiscoveryIcon,
} from 'components/icons';
import { NavigationLink } from 'components';
import { useRouteMatch } from 'utils/useRouteMatch';
import { AuthButton } from 'services/auth';

import { AccountSearch } from './components';

export function Header() {
  const { redirectTo } = useRouteUtils();
  const homeLinkProps = redirectTo.protocols();
  const isHomePage = useRouteMatch(homeLinkProps.href, true);

  return (
    <Root withBorder={isHomePage}>
      <LeftNavigation>
        <LogoBox>
          <Logo fontSize="inherit" />
          <LogoFull fontSize="inherit" />
        </LogoBox>
      </LeftNavigation>
      <Navbar>
        <NavigationLink
          icon={<DiscoveryIcon fontSize="inherit" />}
          label="Discovery"
          type="button"
          {...homeLinkProps}
        />
        <AccountSearch />
      </Navbar>
      <RightNavigation>
        <AuthButton />
      </RightNavigation>
    </Root>
  );
}

const Root = styled('div', {
  shouldForwardProp: prop => !['withBorder'].includes(String(prop)),
})<{
  withBorder: boolean;
}>(({ withBorder, theme }) => ({
  width: '100%',
  minHeight: 40,
  display: 'flex',
  padding: '4px 8px',
  gap: 16,
  background: theme.palette.background.default,
  borderBottom: withBorder ? `1px solid ${colors.blackOpacity[12]}` : 'none',
  zIndex: theme.zIndex.modal + 1,
}));

const LeftNavigation = styled('div')({
  display: 'flex',
  flexBasis: 0,
  flexGrow: 1,
});

const LogoBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: 24,
});

const Logo = styled(LogoComponent)(({ theme }) => ({
  [theme.breakpoints.up('mobileLG')]: {
    display: 'none',
  },
}));

const LogoFull = styled(LogoFullComponent)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('mobileLG')]: {
    display: 'block',
  },
}));

const Navbar = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
  width: 740,
  flexGrow: 0,
  fontSize: 14,
  color: theme.palette.text.primary,
}));

const RightNavigation = styled('div')({
  flexBasis: 0,
  flexGrow: 1,
  display: 'flex',
  justifyContent: 'end',
});
