'use client';

import { createTheme } from '@mui/material';
import localFont from 'next/font/local';

import { IBMPlexMono } from 'assets/fonts/IBMPlexMono';

import { colors } from './colors';
import { breakpoints } from './breakpoints';

const ArbeitPro = localFont({
  src: [
    {
      path: '../../assets/fonts/ArbeitProRegular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/ArbeitProRegular.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/ArbeitProMedium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../assets/fonts/ArbeitProMedium.woff',
      weight: '500',
      style: 'normal',
    },
  ],
});

export const nextTheme = createTheme({
  breakpoints: { values: breakpoints },
  typography: {
    fontFamily: ArbeitPro.style.fontFamily,
    secondaryFontFamily: IBMPlexMono.style.fontFamily,
    body2: {
      fontSize: 12,

      '@media (min-width: 2560px)': {
        fontSize: 12,
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        fontFamily: ArbeitPro.style.fontFamily,
        body: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: colors.black,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '4px 8px 4px 8px',
          background: colors.blackOpacity[8],
          backdropFilter: 'blur(20px)',
          borderRadius: 4,
          color: colors.black,
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        width: 0,
      },
      styleOverrides: {
        root: {
          fontSize: 20,
          display: 'block',
        },
        fontSizeSmall: {
          fontSize: 16,
        },
        fontSizeLarge: {
          fontSize: 24,
        },
        fontSizeInherit: {
          fontSize: 'inherit',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          paddingLeft: 16,
        },
        indicator: {
          height: 1,
        },
        flexContainer: {
          gap: 16,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '16px 0px 18.5px',
          minWidth: 45,
          color: colors.blackOpacity[48],
          fontSize: 14,
          fontWeight: 400,
          textTransform: 'none',

          '&:hover': {
            color: colors.blackOpacity[64],

            '&::after': {
              content: "''",
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: 1,
              background: colors.blackOpacity[64],
            },
          },

          '&.Mui-selected': {
            color: colors.black,
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: colors.whiteOpacity[80],
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: colors.black,
    },
    background: {
      paper: colors.smokyWhite,
      default: colors.white,
    },
    action: {
      disabled: colors.blackOpacity[26],
      active: colors.blackOpacity[54],
    },
    success: {
      main: colors.white,
    },
    error: {
      main: colors.bittersweet,
    },
    text: {
      primary: colors.black,
      secondary: colors.black,
    },
    amount: {
      positive: colors.greenShamrock,
      negative: colors.scarlet[100],
    },
    progress: {
      default: colors.electricViolet,
      positive: colors.greenShamrock,
      negative: colors.scarlet[100],
    },
  },
});
