import React from 'react';

import { AddressIcon, NextLink } from 'components';
import { styled } from 'core/styles';
import { useRouteUtils } from 'routes';
import { getShortAddress } from 'utils/format';

export function AccountAddressResult({
  address,
  onLinkClick,
}: {
  address: string;
  onLinkClick: () => void;
}) {
  const { redirectTo } = useRouteUtils();

  return (
    <NextLink href={redirectTo.globalAccount({ accountId: address }).href}>
      <AccountAddressBox onClick={() => onLinkClick()}>
        <AccountIcon>
          <AddressIcon address={address} fontSize="inherit" />
        </AccountIcon>
        <AccountDescription>
          <ShortAddress>{getShortAddress(address, 6, 3)}</ShortAddress>
          <FullAddress>{address}</FullAddress>
          <span>User account</span>
        </AccountDescription>
      </AccountAddressBox>
    </NextLink>
  );
}

const AccountAddressBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
});

const AccountIcon = styled('div')({
  fontSize: 32,
});

const AccountDescription = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 14,
});

const ShortAddress = styled('span')(({ theme }) => ({
  display: 'inline',

  [theme.breakpoints.up('mobileMD')]: {
    display: 'none',
  },
}));

const FullAddress = styled('span')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('mobileMD')]: {
    display: 'inline',
  },
}));
