'use client';

import { styled } from '@mui/material';
import React, { ReactNode } from 'react';

import { colors } from 'core/styles';
import { useRouteUtils } from 'routes';
import { useRouteMatch } from 'utils/useRouteMatch';

import { Header } from './components';
import { CustomHostFooter, Footer } from './components/Footer';

export function GlobalLayout({ children }: { children: ReactNode }) {
  const { isCustomHost, productSlug } = useRouteUtils();
  const { redirectTo } = useRouteUtils();
  const homeLinkProps = redirectTo.protocols();
  const isHomePage = useRouteMatch(homeLinkProps.href, true);

  return (
    <Root>
      {!isCustomHost && (
        <StickyTop enabled={!isHomePage}>
          <Header />
        </StickyTop>
      )}
      {children}
      {isCustomHost ? <CustomHostFooter productSlug={productSlug} /> : <Footer />}
    </Root>
  );
}

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  minHeight: '100vh',
  background: `linear-gradient( ${colors.blackOpacity[4]}, transparent 1px), linear-gradient( 90deg, ${colors.blackOpacity[4]}, ${colors.whiteOpacity[80]} 1px)`,
  backgroundSize: '80px 80px',
  backgroundPosition: 'center center',
});

const StickyTop = styled('div', {
  shouldForwardProp: prop => prop !== 'enabled',
})<{
  enabled: boolean;
}>(({ enabled }) => ({
  position: enabled ? 'sticky' : 'static',
  top: 0,
  width: '100%',
  zIndex: 4,
}));
