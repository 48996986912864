'use client';

import '../utils/loadAdditionalDeps';
import React, { useMemo } from 'react';
import { usePathname } from 'next/navigation';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';

import { theme } from 'core/styles';
import { ErrorBoundary } from 'components';
import { nextTheme } from 'core/styles/nextTheme';
import { GlobalLayout } from 'layouts';
import { RouteUtilsContextProvider, makeRouteUtils } from 'routes';

import { EmotionCacheProvider } from './EmotionCacheProvider';

export function AppWrapper({ host, children }: { host: string | null; children: React.ReactNode }) {
  const pathname = usePathname();
  const isWidget = pathname.includes('widgets');
  const appTheme = useMemo(
    () =>
      isWidget
        ? createTheme({
            ...theme,
            palette: {
              ...theme.palette,
              background: { ...theme.palette.background, default: 'transparent' },
            },
          })
        : nextTheme,
    [isWidget],
  );

  return (
    <EmotionCacheProvider options={{ key: 'css', prepend: false }}>
      <ErrorBoundary>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <RouteUtilsContextProvider value={makeRouteUtils(host)}>
            {isWidget ? children : <GlobalLayout>{children}</GlobalLayout>}
          </RouteUtilsContextProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </EmotionCacheProvider>
  );
}
