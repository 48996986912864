import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

// tslint:disable:max-line-length
function DiscoveryIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="8" stroke="currentColor" fill="none" strokeWidth="1.25" />
      <circle cx="12" cy="12" r="1" fill="currentColor" />
      <path
        d="M10.1254 10.1251L16.5074 7.49267L13.875 13.8746L7.49293 16.5071L10.1254 10.1251Z"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.25"
        strokeLinejoin="bevel"
      />
    </SvgIcon>
  );
}

export { DiscoveryIcon };
