import { parse, registerCustom, stringify } from 'superjson';
import {
  Currency,
  Fraction,
  LiquidityAmount,
  PercentAmount,
  Token,
  TokenAmount,
} from '@akropolis-web/primitives';

import { Right, isRight, Left, isLeft } from 'utils/either';

registerCustom(
  {
    isApplicable: (value): value is Currency => {
      return value instanceof Currency;
    },
    serialize: (value: Currency) => {
      return {
        symbol: value.symbol,
        decimals: value.decimals,
      };
    },
    deserialize: value => {
      return new Currency(value.symbol, value.decimals);
    },
  },
  'Currency',
);
registerCustom(
  {
    isApplicable: (value): value is Token => {
      return value instanceof Token;
    },
    serialize: (value: Token) => {
      return {
        address: value.address,
        symbol: value.symbol,
        network: value.network,
        decimals: value.decimals,
      };
    },
    deserialize: value => {
      return new Token(value.address, value.symbol, value.decimals, value.network);
    },
  },
  'Token',
);
registerCustom(
  {
    isApplicable: (value): value is Fraction => {
      return value instanceof Fraction;
    },
    serialize: (value: Fraction) => {
      return {
        numerator: value.numerator.toString(),
        denominator: value.denominator.toString(),
      };
    },
    deserialize: value => {
      return new Fraction(value.numerator, value.denominator);
    },
  },
  'Fraction',
);
registerCustom(
  {
    isApplicable: (value): value is TokenAmount => {
      return value instanceof TokenAmount;
    },
    serialize: (value: TokenAmount) => {
      return {
        token: stringify(value.currency),
        amount: stringify(value.toFraction()),
      };
    },
    deserialize: value => {
      return new TokenAmount(parse<Fraction>(value.amount), parse<Token>(value.token));
    },
  },
  'TokenAmount',
);
registerCustom(
  {
    isApplicable: (value): value is LiquidityAmount => value instanceof LiquidityAmount,
    serialize: (value: LiquidityAmount) => {
      return {
        currency: stringify(value.currency),
        amount: stringify(value.toFraction()),
      };
    },
    deserialize: value =>
      new LiquidityAmount(parse<Fraction>(value.amount), parse<Currency>(value.currency)),
  },
  'LiquidityAmount',
);
registerCustom(
  {
    isApplicable: (value): value is PercentAmount => value instanceof PercentAmount,
    serialize: (value: PercentAmount) => {
      return {
        amount: stringify(value.toFraction()),
      };
    },
    deserialize: value => new PercentAmount(parse<Fraction>(value.amount)),
  },
  'PercentAmount',
);
registerCustom(
  {
    isApplicable: (value): value is Right<unknown> => isRight(value),
    serialize: (value: Right<unknown>) => {
      return {
        data: stringify(value.value.right),
      };
    },
    deserialize: value => new Right<unknown>(parse(value.data)),
  },
  'Right',
);
registerCustom(
  {
    isApplicable: (value): value is Left => isLeft(value),
    serialize: (value: Left) => {
      return {
        data: stringify(value.value.left),
      };
    },
    deserialize: value => new Left(parse(value.data)),
  },
  'Left',
);
