import React from 'react';

import { colors, styled } from 'core/styles';
import {
  ABOUT_US,
  BLOG,
  GET_IN_TOUCH,
  LEGAL_NOTICE_URL,
  PRIVACY_NOTICE_URL,
  TELEGRAM_URL,
  TWITTER_URL,
} from 'env';
import { NavigationLink } from 'components';

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Root>
      <LeftContainer>
        <LongText>© Apostro Labs Inc. 2022-{currentYear}</LongText>
        <ShortText>Apostro © {currentYear}</ShortText>
      </LeftContainer>
      <CenterContainer>
        <NavigationLink label="Twitter" target="_blank" href={TWITTER_URL} type="text-secondary" />
        <NavigationLink
          label="Telegram"
          target="_blank"
          href={TELEGRAM_URL}
          type="text-secondary"
        />
        <NavigationLink
          label="Email us"
          target="_blank"
          href={GET_IN_TOUCH}
          type="text-secondary"
        />
        <NavigationLink label="Blog" target="_blank" href={BLOG} type="text-secondary" />
      </CenterContainer>
      <RightContainer>
        <NavigationLink label="About us" target="_blank" href={ABOUT_US} type="text-secondary" />
        <NavigationLink
          label={
            <>
              <LongText>Legal Notice</LongText>
              <ShortText>Legal</ShortText>
            </>
          }
          target="_blank"
          href={LEGAL_NOTICE_URL}
          type="text-secondary"
        />
        <NavigationLink
          label={
            <>
              <LongText>Privacy Notice</LongText>
              <ShortText>Privacy</ShortText>
            </>
          }
          target="_blank"
          href={PRIVACY_NOTICE_URL}
          type="text-secondary"
        />
      </RightContainer>
    </Root>
  );
}

const CENTER_WIDTH = 740;
const ROOT_GAP = 70;

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: 48,
  display: 'flex',
  columnGap: ROOT_GAP,
  padding: '8px 16px',
  fontSize: 14,
  color: theme.palette.text.primary,
  background: theme.palette.background.default,
  borderTop: `1px solid ${colors.whiteOpacity[12]}`,

  [theme.breakpoints.down('tabletXS')]: {
    flexWrap: 'wrap',
    rowGap: 8,
  },
}));

const LeftContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexBasis: `calc((100% - ${CENTER_WIDTH}px - ${ROOT_GAP * 2}px ) / 2)`,
  flexGrow: 0,
  flexShrink: 0,
  opacity: 0.4,
  whiteSpace: 'nowrap',
});

const LongText = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('desktopXS')]: {
    display: 'none',
  },
}));
const ShortText = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('desktopXS')]: {
    display: 'none',
  },
}));

const CenterContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 24,
  width: CENTER_WIDTH,
  flexGrow: 0,
});

const RightContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 24,
  flexBasis: 0,
  flexGrow: 1,

  [theme.breakpoints.down('tabletXS')]: {
    justifyContent: 'flex-start',
  },
}));
