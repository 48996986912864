import React from 'react';
import { Link, styled } from '@mui/material';

import { LogoApostroForCustomHost } from 'components/icons';
import { colors } from 'core/styles/colors';
import { APOSTRO_LANDING_URL } from 'env';
import { ProductSlug } from 'domain/types';

export function CustomHostFooter({ productSlug }: { productSlug: ProductSlug }) {
  return (
    <Root>
      <FooterLink
        href={`${APOSTRO_LANDING_URL}?utm_source=${productSlug}&utm_medium=referral`}
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        <span>Powered by</span>
        <LogoBox>
          <LogoApostroForCustomHost fontSize="inherit" />
        </LogoBox>
      </FooterLink>
    </Root>
  );
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '0 20px 20px',
  fontSize: 12,

  [theme.breakpoints.up('tabletXS')]: {
    justifyContent: 'end',
  },
  [theme.breakpoints.up('desktopMD')]: {
    padding: '0 78px 20px',
  },
  [theme.breakpoints.up('desktopLG')]: {
    padding: '0 109px 20px',
  },
  [theme.breakpoints.up('desktopXL')]: {
    padding: '0 188px 20px',
  },
}));

const FooterLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '26px',
  fontSize: 12,
  padding: '0 9px 0 6px',
  borderRadius: '10px',
  background: colors.smokyWhite,
  cursor: 'pointer',

  [theme.breakpoints.up('tabletLG')]: {
    height: '34px',
    padding: '0 10px',
  },
})) as typeof Link;

const LogoBox = styled('div')(({ theme }) => ({
  fontSize: '16.8px',
  marginTop: '-0.2em',
  marginLeft: '5.5px',

  [theme.breakpoints.up('tabletLG')]: {
    fontSize: '21.2px',
    marginLeft: '5px',
    marginTop: '-0.34em',
  },
}));
