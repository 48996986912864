import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { styled } from '@mui/material/styles';

// tslint:disable:max-line-length
function LogoComponent(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon viewBox="0 0 20 25" {...props}>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M0 0h3.462v7.796H0Zm7.308 7.796h11.154v3.508H7.308Zm1.154 16.37-.001-2.728H5V17.93h3.461v-2.728l6.923-.001v-3.897H20v12.863H8.462Zm6.922-5.457H9.615v1.949h5.769v-1.949Z"
      />
    </SvgIcon>
  );
}

const Logo = styled(LogoComponent)({
  width: 'unset',
});

export { Logo };
