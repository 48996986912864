import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

// tslint:disable:max-line-length
function SearchIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <line
        x1="16.6419"
        y1="15.7581"
        x2="19.7532"
        y2="18.8693"
        stroke="currentColor"
        strokeWidth="1.25"
      />
      <circle cx="12" cy="11" r="6" stroke="currentColor" strokeWidth="1.25" fill="none" />
    </SvgIcon>
  );
}

export { SearchIcon };
