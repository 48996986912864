import React from 'react';
import Link, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { CSSInterpolation } from '@mui/material';

import { colors, styled } from 'core/styles';
import { useRouteMatch } from 'utils/useRouteMatch';
import { MaybeArray } from 'utils/types';
import { RedirectLinkProps } from 'domain/types';

import { NextLink } from '../NextLink';

type LinkType = 'tab' | 'button' | 'text' | 'text-secondary';

export type LinkProps = RedirectLinkProps & {
  icon?: JSX.Element;
  label: string | JSX.Element;
  baseRoutes?: MaybeArray<string>;
  /** @default "text" */
  type?: LinkType;
};

export function NavigationLink(props: LinkProps) {
  const { icon, label, href, baseRoutes, target, type = 'text' } = props;

  const match = useRouteMatch(baseRoutes || href, true);
  const hidePointer = useRouteMatch(href, true);

  return (
    <Root
      component={NextLink}
      href={href}
      underline="none"
      isActive={match}
      hidePointer={hidePointer}
      target={target}
      type={type}
    >
      {icon && <IconContainer>{icon}</IconContainer>}
      {label}
    </Root>
  );
}

const Root = styled(Link, {
  shouldForwardProp: prop => !['isActive', 'hidePointer', 'type'].includes(String(prop)),
})<{
  isActive: boolean;
  hidePointer: boolean;
  type: LinkType;
}>(({ isActive, hidePointer, type }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  color: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  padding: 0,
  cursor: hidePointer ? 'default' : 'pointer',
  transition: 'all 200ms',
  whiteSpace: 'nowrap',

  '&:focus': {
    outline: 'none',
  },
  ...getRootStylesByType({ type, isActive }),
})) as (
  props: MuiLinkProps<
    typeof NextLink,
    {
      isActive: boolean;
      hidePointer: boolean;
      type: LinkType;
    }
  >,
) => React.ReactElement;

function getRootStylesByType({
  type,
  isActive,
}: {
  isActive: boolean;
  type: LinkType;
}): Record<string, CSSInterpolation> {
  switch (type) {
    case 'button':
      return {
        padding: '4px 12px 4px 6px',
        borderRadius: '100px',
        background: isActive ? colors.silver : colors.smokyWhite,
        color: isActive ? colors.white : colors.pearlDarkGray,

        '&:hover': {
          background: colors.silver,
        },
        '&:active': {
          background: isActive ? colors.silver : colors.smokyWhite,
          color: colors.white,
        },
      };
    case 'tab':
      return {
        padding: '4px 0 15px',
        borderBottom: isActive ? `1px solid ${colors.black}` : `1px solid transparent`,
        color: isActive ? colors.black : colors.pearlDarkGray,

        '&:hover': {
          color: isActive ? colors.black : colors.black,
          borderBottom: isActive
            ? `1px solid ${colors.black}`
            : `1px solid ${colors.pearlDarkGray}`,
        },
      };
    case 'text-secondary':
      return {
        opacity: 0.4,

        '&:hover': {
          opacity: 1,
        },
      };
    case 'text':
      return {
        opacity: 1,

        '&:hover': {
          opacity: 0.4,
        },
      };
  }
}

const IconContainer = styled('span')(() => ({
  fontSize: 24,
  marginRight: 4,
  color: 'inherit',
}));
